import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { ComponentLoader } from 'src/components/ComponentLoader/ComponentLoader';
import { RouterRootPaths } from 'src/types/Router';

const ListScreen = ComponentLoader(lazy(() => import('src/screens/brands/list/BrandsListScreen')));
const ViewScreen = ComponentLoader(
  lazy(() => import('src/screens/brands/view/BrandViewScreen'))
);
const CreateScreen = ComponentLoader(
  lazy(() => import('src/screens/brands/create/BrandCreateScreen'))
);

export const BrandsRoutes = [
  {
    path: RouterRootPaths.BRANDS,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <ListScreen />
      },
      {
        path: 'create',
        element: <CreateScreen />
      },
      {
        path: ':id',
        element: <ViewScreen />
      },
    ]
  },
];

