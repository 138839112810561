import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { ComponentLoader } from 'src/components/ComponentLoader/ComponentLoader';
import { RouterRootPaths } from 'src/types/Router';

const ListScreen = ComponentLoader(lazy(() => import('src/screens/transactions/list/TransactionsListScreen')));
const ViewScreen = ComponentLoader(
  lazy(() => import('src/screens/transactions/view/TransactionViewScreen'))
);
const CreateScreen = ComponentLoader(
  lazy(() => import('src/screens/transactions/create/TransactionCreateScreen'))
);
const CheckoutScreen = ComponentLoader(
  lazy(() => import('src/screens/transactions/checkout/TransactionCheckoutScreen'))
);

export const TransactionsRoutes = [
  {
    path: RouterRootPaths.TRANSACTIONS,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <ListScreen />
      },
      {
        path: 'create',
        element: <CreateScreen />
      },
      {
        path: 'checkout',
        element: <CheckoutScreen />
      },
      {
        path: ':id',
        element: <ViewScreen />
      },
    ]
  },
];

