import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { ComponentLoader } from 'src/components/ComponentLoader/ComponentLoader';
import { RouterRootPaths } from 'src/types/Router';

const ListScreen = ComponentLoader(lazy(() => import('src/screens/content/list/ContentListScreen')));
const ViewScreen = ComponentLoader(
  lazy(() => import('src/screens/content/view/ContentViewScreen'))
);

export const ContentRoutes = [
  {
    path: RouterRootPaths.CONTENT,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <ListScreen />
      },
      {
        path: ':id',
        element: <ViewScreen />
      },
    ]
  },
];

