import ReactDOM from 'react-dom';
import 'src/mocks';
import 'nprogress/nprogress.css';
import * as serviceWorker from 'src/serviceWorker';
import { AppWrapper } from './AppWrapper';

ReactDOM.render(
  <AppWrapper />,
  document.getElementById('root')
);

serviceWorker.unregister();
