import { Amplify } from 'aws-amplify';
import { getEnvironment, isProduction, isStaging } from './utilities/Environment';
import awsconfig from './aws-exports';

// Sentry.init({
//   dsn: 'https://ba311bbaa40d453ebd29d06155e6d798@o4504891114192896.ingest.sentry.io/4504891147747328',
//   enableInExpoDevelopment: isProduction() || isStaging(),
//   environment: getEnvironment(),
//   tracesSampleRate: 1.0,
//   enableAutoPerformanceTracing: true,
// });

Amplify.configure(awsconfig);

export {};