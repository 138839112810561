import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Auth

const SignIn = Loader(
  lazy(() => import('src/screens/auth/SignInScreen'))
);

const RecoverPassword = Loader(
  lazy(() => import('src/screens/auth/RecoverPassword/RecoverPasswordScreen'))
);

const authRoutes = [
  {
    path: 'sign-in',
    element: (
      <Guest>
        <SignIn />
      </Guest>
    ),
  },
  {
    path: 'recover-password',
    element: (
      <Guest>
        <RecoverPassword />
      </Guest>
    ),
  },
];

export default authRoutes;
