/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "4e655bb04fca48b79685712f9dcebf92",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "4e655bb04fca48b79685712f9dcebf92",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://nox11kmd6b.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "transactions",
            "endpoint": "https://iwy9s9ovnf.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gnjc5prwufdgrccbblqjtxs47e.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-n7cjedjxdveblom7avw2wjwpc4",
    "aws_cognito_identity_pool_id": "us-east-1:45b037b1-a1d2-4597-8b83-de637dc9bc78",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_4aaK4VABL",
    "aws_user_pools_web_client_id": "5i95phfd1v05m9noj718e9oi6f",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "chargebc7484aa272a4a8c8b6238d5f45239fc162006-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
